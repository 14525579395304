



















































































































import Vue from 'vue'

function isRange(s: string) {
  return /^[0-9]+,[0-9]+$/.test(s)
}
let validateRange = (rule: any, value: any, callback: any) => {
  if (!isRange(value)) {
    callback(new Error('区间填写错误'))
  } else {
    callback()
  }
}

export default Vue.extend({
  data() {
    return {
      loading: true,
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
        orderBy: {},
      },
      entities: [],
      showDialog: false,
      form: {
        price: '',
        num: '',
      },
      rules: {
        num: [{ required: true, message: '请输入数量' }],
        price: [{ required: true, message: '请输入价格' }],
      },
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      const res = await this.$http.request({
        url: 'cart_codes',
        method: 'get',
        params: this.paginate,
      })
      this.entities = res.data.data
      this.loading = false
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleSortChange({ prop, order }: { prop: any; order: any }) {
      if (order) {
        this.paginate.orderBy = {
          prop,
          order: order.replace('ending', ''),
        }
      } else {
        this.paginate.orderBy = {}
      }
      this.fetch()
    },
    handleRemove(row: any) {
      this.$confirm(`此操作将永久删除"${row.id}", 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`cart_codes/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    async handleReset(row: any) {
      this.$confirm(`是否要还原激活码 "${row.code}", 是否继续?`, '提示', {
        type: 'warning',
      }).then(async () => {
        await this.$http.put(`cart_codes/reset/${row.code}`)
        this.$message.success('还原成功')
        this.fetch()
      })
    },
    async handleExport() {
      const response = await this.$http.post(
        'cart_codes/export',
        this.paginate,
        {
          responseType: 'arraybuffer',
        },
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `table.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    async handleExportAll() {
      const response = await this.$http.post(
        'cart_codes/export?paginate=0',
        this.paginate,
        {
          responseType: 'arraybuffer',
        },
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `table.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    async genrateCodes() {
      ~(this.$refs.form as any).validate(async (valid: boolean) => {
        if (valid) {
          const confirm = await this.$confirm('确定要生成吗')
          if (!confirm) return

          await this.$http.post('cart_codes/batchGenerate', this.form)
          this.$message('后台生成中，请留意激活码数量增长')
          this.showDialog = false
        }
      })
    },
  },
})
